var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.order)?_c('div',{staticClass:"card border-0 mb-3"},[_c('div',{staticClass:"card-header"},[(_vm.order.title)?_c('h5',{staticClass:"mb-0 mt-1"},[_vm._v(_vm._s(_vm.order.title))]):_c('h5',{staticClass:"mb-0 mt-1"},[_vm._v("Order Items")])]),_c('div',{staticClass:"card-body pt-1"},[(_vm.order.type === 'consultation')?_c('table',{staticClass:"table"},[_vm._m(0),_c('tbody',_vm._l((_vm.order.items.filter(function (item) {
                  // @todo This should never happen on a consultation?
                  return item.product ? item.product.model_type !== 'App\\Models\\ProductTypeShippingMethod' : true;
                })),function(i){return _c('tr',{key:i.id},[_c('td',[_vm._v(_vm._s(i.id))]),_c('td',[_vm._v(" "+_vm._s(i.product.title)+" "),(_vm.order.consultation && _vm.order.consultation.event)?_c('i',[_vm._v(" ("+_vm._s(_vm._f("formatDate")(_vm.order.consultation.event.start))+") ")]):_vm._e()]),_c('td',[_vm._v(_vm._s(i.quantity))]),_c('td',[_vm._v(_vm._s(i.currency.symbol)+_vm._s((i.cost * i.quantity).toFixed(2)))])])}),0)]):_c('table',{staticClass:"table"},[_vm._m(1),_c('tbody',_vm._l((_vm.order.items.filter(function (item) {
                  // Clinic products currently have no product. Return if
                  // not shipping method.
                  return item.product ? item.product.model_type !== 'App\\Models\\ProductTypeShippingMethod' : true;
                })),function(i){return _c('tr',{key:i.id},[_c('td',[_vm._v(_vm._s(i.id))]),_c('td',[_vm._v(_vm._s(i.name))]),_c('td',[_vm._v(_vm._s(i.quantity))]),_c('td',[_vm._v(" "+_vm._s(i.currency.symbol)+_vm._s((i.cost * i.quantity).toFixed(2))+" ")]),_c('td',[_vm._v(_vm._s(i.currency.symbol)+_vm._s((i.retail * i.quantity).toFixed(2)))]),_c('td',[_vm._v(_vm._s(_vm._f("capitalize")(i.fulfilment)))])])}),0)])])]):_vm._e()}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#x")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Product")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Qty")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Price")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('thead',[_c('tr',[_c('th',{attrs:{"scope":"col"}},[_vm._v("#q")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Product")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Qty")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Cost Price")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Retail Price")]),_c('th',{attrs:{"scope":"col"}},[_vm._v("Fulfillment")])])])}]

export { render, staticRenderFns }