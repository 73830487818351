<template>
  <div class="card border-0 mb-3" v-if="order">
    <div class="card-header">
      <h5 class="mb-0 mt-1">
        Client Payment Instalments
        <div class="float-end">
          <button class="btn btn-sm btn-outline-primary"
                  v-if="!editInstalment && order.status !== 'paid'"
                  @click="editInstalment = true"
          >
            <i class="far fa-edit me-1" />
            Edit
          </button>
        </div>
      </h5>
    </div>
    <div class="card-body pt-1">
      <table class="table">
        <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Amount</th>
          <th scope="col">Status</th>
          <th scope="col">Payment Due Date</th>
          <th scope="col" class="table-col-min"></th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="i in order.instalments" :key="i.id">
          <td>{{ i.id }}</td>
          <td>
            <div v-if="!editInstalment || i.status !== 'not_paid'">
              {{order.currency.symbol}}{{ i.total.toFixed(2) }}
            </div>
            <div v-else>
              <currency-input
                  v-model="i.total"
                  :currency="order.currency.name"
                  locale="gb"
                  placeholder="Enter a Price"
                  id="consultPrice"
                  :min="0"
              />
            </div>
          </td>
          <td class="text-capitalize">{{ i.status | formatText }}</td>
          <td>
            <div v-if="!editInstalment || i.status !== 'not_paid'">
              {{ i.due_date | formatDate }}
            </div>
            <div v-else>
              <utc-flat-pickr v-model="i.due_date" :req="false" />
            </div>
          </td>
          <td class="table-col-min">
            <button
              v-if="i.status === 'paid' && user.clinic.adyen_integration && user.clinic.adyen_integration.setup_status !== 'pending'"
              class="btn btn-xs btn-danger float-end me-1"
              @click="issueRefund(i)"
            >
              <i class="far fa-exchange me-1"></i>
              Refund
            </button>
            <button class="btn btn-xs btn-outline-danger"
                    @click="deleteInstalment(i)"
                    v-if="editInstalment && i.status === 'not_paid'">
              <i class="far fa-trash" />
            </button>
            <span v-if="isOverdue(i)" class="badge bg-danger ms-3 float-end">Overdue</span>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="row" v-if="editInstalment">
        <div class="col text-end">
          <button class="btn btn-sm btn-outline-primary me-2"
                  @click="addInstalment"
          >
            Add Instalment
          </button>
          <button class="btn btn-sm btn-primary"
                  @click="saveInstalments"
          >
            <i class="far fa-save me-1" />
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { CurrencyInput } from "vue-currency-input";
import UtcFlatPickr from "../../../globals/UtcFlatPickr";
export default {
  props: ["order"],
  components: {UtcFlatPickr, CurrencyInput },
  data() {
    return {
      editInstalment: false
    }
  },
  computed: {
    user() {
      return this.$store.getters['auth/user'];
    },
  },
  methods: {
    addInstalment() {
      this.order.instalments.push(
        {
          order_id: this.order.id,
          total: 0,
          status: 'not_paid'
        }
      )
    },
    saveInstalments() {
      this.$axios.post(
          process.env.VUE_APP_API_URL + "/orders/api/" + this.order.id + "/instalments",
          this.order.instalments
      ).then(({ data }) => {
          this.$EventBus.$emit("alert", data);
          this.editInstalment = false;
        });
    },
    deleteInstalment(instalment) {
      let existingInstalmentIndex = this.order.instalments.findIndex(
          el => el == instalment
      );
      this.order.instalments.splice(existingInstalmentIndex, 1)
    },
    isOverdue(instalment) {
      if (instalment.due_date){
        return moment(instalment.due_date) < moment.now();
      } else {
        return false;
      }
    },
    issueRefund(i) {
      var confirmed = confirm(
          'Are you sure you wish to refund payment for this instalment?'
      );

      if (confirmed) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/orders/api/instalments/" + i.id + "/issue-refund")
            .then(({ data }) => {
              this.$EventBus.$emit("alert", data);
              this.$emit("refresh");
            });
      }
    }
  },
  filters: {
    formatDate(date) {
      if (date) {
        return moment(date).format("LL");
      }
      return '';
    },
    formatText(str) {
      return str.replace(/_/g, " ");
    },
  }
}
</script>
<style>
th {
  border-top: none !important;
}
</style>
