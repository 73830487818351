<template>
  <div>
    <busy :visible="loading"/>
    <div v-if="!loading" v-html="invoice"></div>
  </div>
</template>
<script>
export default {
  props: ["order"],
  data() {
    return {
      loading: true,
      invoice: null,
    }
  },
  methods: {
    fetchInvoice() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/invoice-client",
          )
          .then(({ data }) => {
            this.invoice = data;
            this.loading = false;
          });
    }
  },
  mounted() {
    this.fetchInvoice();
  }
}
</script>
<style>
th {
  border-top: none !important;
}
</style>
