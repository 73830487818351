<template>
    <div class="alert alert-danger" role="alert">
  <h4 class="alert-heading">Permission Error</h4>
  <p>You do not have access to view this {{ itemType ? itemType : 'item' }}</p>
</div>
</template>
<script>
export default {
    props: ['itemType']
};
</script>
