<template>
  <div class="card card-default mb-3">
    <div class="card-body bg-white">
      <div class="row">
        <div class="col-auto my-auto">
          <h4 class="mb-0">Order #{{ order.id }}</h4>
        </div>
        <div class="col-auto my-auto ms-auto">
          <router-link
              :to="'/clients/' + order.client.id"
              class="btn btn-outline-primary"
          >View {{ order.client.name }}'s Profile</router-link
          >
        </div>
        <div class="col-auto my-auto">
          <div class="dropdown">
            <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                id="invoicingDropDown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Invoicing
            </button>
            <div
                class="dropdown-menu"
                aria-labelledby="invoicingDropDown"
            >
              <button
                  class="dropdown-item"
                  @click="generateClientInvoice(order)"
              >
                <i class="fas fa-download me-2"></i>View Client Invoice
              </button>

              <div class="dropdown-divider"></div>
              <a class="dropdown-item" @click="emailInvoiceClient">
                <i class="fas fa-envelope me-2"></i>Email Invoice to
                Client
              </a>
            </div>
          </div>
        </div>
        <div class="col-auto my-auto">
          <div class="dropdown">
            <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                id="paymentsDropDown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                :disabled="order.payment_status == 'paid'"
            >
              Payments
            </button>
            <div class="dropdown-menu" aria-labelledby="paymentsDropDown">
              <router-link
                  class="dropdown-item"
                  :to="'/orders/' + order.id + '/payment-card'"
              >Take Card Payment</router-link
              >

              <router-link
                  class="dropdown-item"
                  :to="'/orders/' + order.id + '/payment-cash'"
              >Record External Payment</router-link
              >

              <a
                  v-if="order.status !== 'paid'"
                  class="dropdown-item"
                  @click="sendPaymentReminder"
              >Send Payment Reminder</a
              >
            </div>
          </div>
        </div>

        <!--  -->

        <div class="col-auto my-auto">
          <div class="dropleft">
            <button
                class="btn btn-primary dropdown-toggle"
                type="button"
                id="emailDropDown"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
            >
              Emails
            </button>
            <ul class="dropdown-menu" aria-labelledby="emailDropDown">
              <li
                  class="dropdown-item cursor-pointer"
                  @click="sendClientEmailConfirmation"
              >
                Send Client Email Confirmation
              </li>

              <li
                  class="dropdown-item cursor-pointer"
                  @click="sendClientPaymentLink"
              >
                Send Client Payment Link
              </li>
            </ul>
          </div>
        </div>

        <div class="col-auto my-auto" v-if="order.status !== 'cancelled'">
          <button class="btn btn-outline-danger" @click="cancelOrder">Cancel Order</button>
        </div>

        <!--  -->
      </div>

      <!--  -->

      <div class="row mt-3">
        <div class="col">
          <div class="card text-center border-0 bg-light">
            <div class="card-body">
              <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                Fulfillment
              </p>
              <p v-if="!order.supplier_id" class="text-capitalize">
                {{ order.clinic.name | formatText }}
              </p>
              <p v-if="order.supplier_id" class="text-capitalize">
                {{ order.supplier.name | formatText }}
              </p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card text-center border-0 bg-light">
            <div class="card-body">
              <button v-if="order.status === 'cancelled'"
                      style="position: absolute; right: 5px; bottom: 5px;"
                      class="btn btn-light"
                      @click=softDeleteOrder>
                <i class="far fa-trash"></i>
              </button>
              <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                Status
              </p>
              <p
                v-if="order.status === 'paid' && order.api_status && order.api_status !== 'new'"
                class="text-capitalize">
                {{ order.api_status | formatText }}
              </p>
              <p v-else class="text-capitalize">
                {{ order.status | formatText }}
              </p>
            </div>
          </div>
        </div>

        <div class="col">
          <div class="card text-center border-0 bg-light">
            <div class="card-body">
              <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                Client
              </p>
              <p>{{ order.client.name }}</p>
            </div>
          </div>
        </div>
        <div class="col">
          <div class="card text-center border-0 bg-light">
            <div class="card-body">
              <p class="mb-1 fw-bold text-uppercase mt-2 mb-0">
                Total
              </p>
              <p>{{order.price_display ? order.price_display : '-'}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
  methods: {
    generateClientInvoice(order) {
      document.title = 'Invoice - Order ' + order.id;
      window.print();
      document.title = 'Swandoola Practitioners Portal'
    },
    sendClientPaymentLink() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/email-client-payment-link"
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
    },
    sendClientEmailConfirmation() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/email-client-confirmation"
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
    },
    emailInvoiceClient() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/email-invoice-client"
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
    },
    sendPaymentReminder() {
      this.$axios
          .get(
              process.env.VUE_APP_API_URL +
              "/orders/" +
              this.order.id +
              "/email-client-payment-reminder"
          )
          .then(({ data }) => {
            this.$EventBus.$emit("alert", data);
          });
    },
    cancelOrder() {
      var confirmed = true
      if (this.order.status === 'not_paid'){
        confirmed = confirm(
            'Are you sure you wish to cancel this order? No further payments against this order will be possible.'
        );
      } else {
        confirmed = confirm(
            'Are you sure you wish to cancel this order? Payments have already been made against this order. You may need to refund these to your client.'
        );
      }
      if (confirmed) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/orders/" + this.order.id + "/cancel")
            .then(({ data }) => {
              this.order = data.order;
              this.$EventBus.$emit("alert", data);
            });
      }
    },
    softDeleteOrder() {
      var confirmed = confirm(
          'Are you sure you wish to remove this order? It will no longer show in your practitioner portal. This is not reversible.'
      );

      if (confirmed) {
        this.$axios.post(process.env.VUE_APP_API_URL + "/orders/" + this.order.id + "/soft-delete")
            .then(({ data }) => {
              this.order = data.order;
              this.$EventBus.$emit("alert", data);
              this.$router.go(-1);
            });
      }
    }
  },
  filters: {
    formatText(str) {
      return str.replace(/_/g, " ");
    },
    formatDate(date) {
      return moment(date).format("Do MMM YYYY");
    },
    formatDateTime(date) {
      return moment(date).format("Do MMM YYYY @ H:mm");
    },
    formatUnderScored(text) {
      return text.replace(/_/g, " ");
    },
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
  },
}
</script>
<style>
th {
  border-top: none !important;
}
</style>
