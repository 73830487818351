<template>
  <div class="card border-0 mb-3" v-if="order">
    <div class="card-header">
      <h5 class="mb-0 mt-1" v-if="order.title">{{ order.title }}</h5>
      <h5 class="mb-0 mt-1" v-else>Order Items</h5>
    </div>
    <div class="card-body pt-1">
      <table class="table" v-if="order.type === 'consultation'">
        <thead>
        <tr>
          <th scope="col">#x</th>
          <th scope="col">Product</th>
          <th scope="col">Qty</th>
          <th scope="col">Price</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="i in order.items.filter(function (item) {
                    // @todo This should never happen on a consultation?
                    return item.product ? item.product.model_type !== 'App\\Models\\ProductTypeShippingMethod' : true;
                  })"
            :key="i.id"
        >
          <td>{{ i.id }}</td>
          <td>
            {{ i.product.title }}
            <i v-if="order.consultation && order.consultation.event">
              ({{order.consultation.event.start | formatDate }})
            </i>
          </td>
          <td>{{ i.quantity }}</td>
          <td>{{i.currency.symbol}}{{ (i.cost * i.quantity).toFixed(2) }}</td>
        </tr>
        </tbody>
      </table>
      <table class="table" v-else>
        <thead>
        <tr>
          <th scope="col">#q</th>
          <th scope="col">Product</th>
          <th scope="col">Qty</th>
          <th scope="col">Cost Price</th>
          <th scope="col">Retail Price</th>
          <th scope="col">Fulfillment</th>
        </tr>
        </thead>
        <tbody>
        <tr
            v-for="i in order.items.filter(function (item) {
                    // Clinic products currently have no product. Return if
                    // not shipping method.
                    return item.product ? item.product.model_type !== 'App\\Models\\ProductTypeShippingMethod' : true;
                  })"
            :key="i.id"
        >
          <td>{{ i.id }}</td>
          <td>{{ i.name }}</td>
          <td>{{ i.quantity }}</td>
          <td>
            {{i.currency.symbol}}{{ (i.cost * i.quantity).toFixed(2) }}
          </td>
          <td>{{i.currency.symbol}}{{ (i.retail * i.quantity).toFixed(2) }}</td>
          <td>{{ i.fulfilment | capitalize }}</td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
export default {
  props: ["order"],
  filters: {
    capitalize: function (value) {
      if (!value) return "";
      value = value.toString();
      return value.charAt(0).toUpperCase() + value.slice(1);
    },
    formatDate(date) {
      return moment(date).format('Do MMM YYYY');
    }
  }
}
</script>
<style>
th {
  border-top: none !important;
}
</style>
